/* eslint-disable no-plusplus */
/* eslint-disable no-nested-ternary */
import { ReactComponent as DeliveryTimeIcon } from '~/assets/icons/delivery-time.svg';
import {
  Background,
  NButton,
  NModalOrderReady,
  NSelector,
  Sidebar,
} from '~/components';

import storage from '~/repositories/storage';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import NCardEstablishment from '~/components/NCardEstablishment';
import NCardEstablishmentWaiting from '~/components/NCardEstablishmentWaiting';
import { useAlerts, useApiEffect, useAuth } from '~/hooks';
import { getVisiblePassenger } from '~/services/establishments';
import { getPassenger } from '~/services/passengers';
import { getDomain } from '~/utils';
import { copyText } from '~/utils/copyText';
import { alertCustomer } from '~/services/trips';
import { Container } from './styles';

const statusOptions = [
  'Em andamento',
  'Finalizados',
  'Cancelados',
  'Pendentes',
];

const Establishment = () => {
  const [running, setRunning] = useState([]);
  const [finished, setFinished] = useState([]);
  const [canceled, setCanceled] = useState([]);
  const [waiting, setWaiting] = useState([]);
  const [amountCount, setAmountCount] = useState({
    canceled: 0,
    finished: 0,
    running: 0,
    waiting: 0,
  });
  const [selectedStatus, setSelectedStatus] = useState('Em andamento');
  const [reset, setReset] = useState(false);
  const itemsPerPage = 50;
  const { newSuccessAlert } = useAlerts();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const { user, setUser } = useAuth();
  const navigate = useNavigate();

  const fetchRunning = async () => {
    const response = await getVisiblePassenger({
      id: user.id,
      status: 'running',
      take: itemsPerPage,
    });
    setRunning(response.data.data);
    setAmountCount(prev => ({
      ...prev,
      running: response.data.statusAmounts.running,
    }));
  };

  const fetchFinished = async () => {
    const response = await getVisiblePassenger({
      id: user.id,
      status: 'finished',
      take: itemsPerPage,
    });
    setFinished(response.data.data);
    setAmountCount(prev => ({
      ...prev,
      finished: response.data.statusAmounts.finished,
    }));
  };

  const fetchCanceled = async () => {
    const response = await getVisiblePassenger({
      id: user.id,
      status: 'canceled',
      take: itemsPerPage,
    });
    setCanceled(response.data.data);
    setAmountCount(prev => ({
      ...prev,
      canceled: response.data.statusAmounts.canceled,
    }));
  };

  const fetchWaiting = async () => {
    const response = await getVisiblePassenger({
      id: user.id,
      status: 'waiting',
      take: itemsPerPage,
    });
    setWaiting(response.data.data);
    setAmountCount(prev => ({
      ...prev,
      waiting: response.data.statusAmounts.waiting,
    }));
  };

  useEffect(() => {
    fetchRunning();
    fetchFinished();
    fetchCanceled();
    fetchWaiting();
  }, [reset]);

  const disembarkationTripId = storage.getItem('desembarque');
  const tripUrlSplitted = window.location.href.split('/viagens');

  useEffect(() => {
    if (
      disembarkationTripId &&
      disembarkationTripId !== '' &&
      disembarkationTripId !== 'undefined'
    ) {
      storage.setItem('desembarque', undefined);
      navigate(`/${getDomain()}/desembarque/${disembarkationTripId}`);
    } else if (tripUrlSplitted[1]) {
      navigate(`/${getDomain()}/`, {
        state: {
          tripUrl: `/${getDomain()}/viagens${tripUrlSplitted[1]}`,
        },
      });
    }
  }, []);

  useApiEffect(
    () => getPassenger(),
    userResponse => {
      setUser(userResponse.data);
    }
  );

  const hasRunning = Boolean(running.length);
  const hasFinished = Boolean(finished.length);
  const hasCanceled = Boolean(canceled.length);
  const hasWaiting = Boolean(waiting.length);

  const handleCreateTrip = () => {
    navigate(`/${getDomain()}/home`, {
      state: {
        passengerId: user.id,
        establishment: true,
      },
    });
  };

  const handleShareLink = () => {
    const hostname = window.location.origin;
    copyText(`${hostname}/${getDomain()}/desembarque/${user.firstTrip}`);

    newSuccessAlert('Link de viagem copiado com sucesso :)');
  };

  return (
    <>
      <Container>
        <Background height="350px" />
        <div className="div_top">
          <Sidebar />
          {!user.firstTrip ? (
            <div className="div_top_inner">
              <h1>Crie seu novo link de viagem</h1>
              <NButton
                label="Criar viagem"
                className="button"
                color="white"
                onClick={handleCreateTrip}
              />
            </div>
          ) : (
            <>
              <div className="div_top_inner">
                <NButton
                  label="Novo pedido"
                  className="button"
                  color="white"
                  onClick={() => {
                    setIsModalVisible(true);
                  }}
                  style={{
                    width: '90vw',
                    maxWidth: 500,
                    height: 40,
                  }}
                />
              </div>
              <div className="div_top_inner">
                <NButton
                  label="Simular preço de entrega"
                  className="button"
                  color="white"
                  onClick={handleShareLink}
                  style={{
                    width: '90vw',
                    maxWidth: 500,
                    height: 40,
                  }}
                />
              </div>
            </>
          )}
        </div>
        <div className="div_center">
          <div className="is_waiting">
            <h2>Pendentes ({amountCount.waiting})</h2>
            {hasWaiting ? (
              waiting.map(trip => (
                <NCardEstablishmentWaiting
                  key={trip.passenger.tripPassengerId}
                  id={trip.passenger.tripPassengerId}
                  orderIsReady={trip.orderIsReady}
                  driver={trip.driver.name}
                  passenger={trip.passenger.name}
                  driverPhotoImage={trip.driver.photoImage}
                  cost={trip.totalCost}
                  address={trip.landingAddress.name}
                  status={trip.tripPassengerStatus}
                  date={trip.finishedAt ?? new Date()}
                  handleReset={() => setReset(prev => !prev)}
                />
              ))
            ) : (
              <div className="empty_state">
                <DeliveryTimeIcon />
                <p>Sem pedidos</p>
              </div>
            )}
          </div>
          <div className="divider" />
          <div className="in_progress">
            <h2>Em andamento ({amountCount.running})</h2>
            {hasRunning ? (
              running.map(trip => (
                <NCardEstablishment
                  key={trip.passenger.tripPassengerId}
                  driver={trip.driver.name}
                  passenger={trip.passenger.name}
                  driverPhotoImage={trip.driver.photoImage}
                  cost={trip.totalCost}
                  address={trip.landingAddress.name}
                  status={trip.tripPassengerStatus}
                  date={trip.finishedAt ?? new Date()}
                />
              ))
            ) : (
              <div className="empty_state">
                <DeliveryTimeIcon />
                <p>Sem pedidos</p>
              </div>
            )}
          </div>
          <div className="divider_2" />
          <div className="was_finished">
            <h2>Finalizados ({amountCount.finished})</h2>
            {hasFinished ? (
              finished.map(trip => (
                <NCardEstablishment
                  key={trip.passenger.tripPassengerId}
                  driver={trip.driver.name}
                  passenger={trip.passenger.name}
                  driverPhotoImage={trip.driver.photoImage}
                  cost={trip.totalCost}
                  address={trip.landingAddress.name}
                  status={trip.tripPassengerStatus}
                  date={trip.finishedAt ?? new Date()}
                />
              ))
            ) : (
              <div className="empty_state">
                <DeliveryTimeIcon />
                <p>Sem pedidos</p>
              </div>
            )}
          </div>
          <div className="divider_3" />
          <div className="was_canceled">
            <h2>Cancelados ({amountCount.canceled})</h2>
            {hasCanceled ? (
              canceled.map(trip => (
                <NCardEstablishment
                  key={trip.passenger.tripPassengerId}
                  driver={trip.driver.name}
                  passenger={trip.passenger.name}
                  driverPhotoImage={trip.driver.photoImage}
                  cost={trip.totalCost}
                  address={trip.landingAddress.name}
                  status={trip.tripPassengerStatus}
                  date={trip.finishedAt ?? new Date()}
                />
              ))
            ) : (
              <div className="empty_state">
                <DeliveryTimeIcon />
                <p>Sem pedidos</p>
              </div>
            )}
          </div>
        </div>

        <div className="div_center_mobile">
          <div className="selector_wrapper">
            <NSelector
              placeholder="Selecione o status da viagem"
              label="Status da viagem"
              options={statusOptions}
              onChange={setSelectedStatus}
              value={selectedStatus}
              className="selector"
            />
          </div>

          {selectedStatus === 'Em andamento' ? (
            <div className="in_progress">
              {hasRunning ? (
                running.map(trip => (
                  <NCardEstablishment
                    key={trip.passenger.tripPassengerId}
                    driver={trip.driver.name}
                    passenger={trip.passenger.name}
                    driverPhotoImage={trip.driver.photoImage}
                    cost={trip.totalCost}
                    address={trip.landingAddress.name}
                    status={trip.tripPassengerStatus}
                    date={trip.finishedAt ?? new Date()}
                  />
                ))
              ) : (
                <div className="empty_state">
                  <DeliveryTimeIcon />
                  <p>Sem pedidos</p>
                </div>
              )}
            </div>
          ) : selectedStatus === 'Finalizados' ? (
            <div className="was_finished">
              {hasFinished ? (
                finished.map(trip => (
                  <NCardEstablishment
                    key={trip.passenger.tripPassengerId}
                    driver={trip.driver.name}
                    passenger={trip.passenger.name}
                    driverPhotoImage={trip.driver.photoImage}
                    cost={trip.totalCost}
                    address={trip.landingAddress.name}
                    status={trip.tripPassengerStatus}
                    date={trip.finishedAt ?? new Date()}
                  />
                ))
              ) : (
                <div className="empty_state">
                  <DeliveryTimeIcon />
                  <p>Sem pedidos</p>
                </div>
              )}
            </div>
          ) : selectedStatus === 'Cancelados' ? (
            <div className="was_canceled">
              {hasCanceled ? (
                canceled.map(trip => (
                  <NCardEstablishment
                    key={trip.passenger.tripPassengerId}
                    driver={trip.driver.name}
                    passenger={trip.passenger.name}
                    driverPhotoImage={trip.driver.photoImage}
                    cost={trip.totalCost}
                    address={trip.landingAddress.name}
                    status={trip.tripPassengerStatus}
                    date={trip.finishedAt ?? new Date()}
                  />
                ))
              ) : (
                <div className="empty_state">
                  <DeliveryTimeIcon />
                  <p>Sem pedidos</p>
                </div>
              )}
            </div>
          ) : null}
        </div>
      </Container>
      <NModalOrderReady
        visible={isModalVisible}
        onConfirm={async ({ name, phone }) => {
          await alertCustomer(user.firstTrip, { name, phone });
        }}
        onClose={() => setIsModalVisible(false)}
      />
    </>
  );
};

export default Establishment;
